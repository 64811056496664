import { Box, Button, Divider, Grid, Slide, Typography } from "@mui/material";
import { NavBar, Footer } from "../components";
import { ThemeConfig } from "../config/theme.config";
import { ScrollToTopButton } from "../components/scrollToTopButton";
import FutureModules from "../const/futureModules";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ScrollToTop from "../wrapper/scrollToTop";
import { useCallback, useEffect, useState } from "react";

export const descriptionZone = (sysmodules, i) => {
   return (
      <Box
         sx={{
            flexDirection: "column",
            flex: 1,

            px: { xs: 1, sm: 6, md: 0 },
            py: { xs: 1, sm: 3, md: 0 },
         }}
      >
         <Grid
            container
            direction={i % 2 === 0 ? "row" : "row-reverse"}
            spacing={{ lg: 1 }}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
         >
            <Grid item xs={12} sx={{ maxWidth: { lg: "450px" } }}>
               <Box
                  sx={{
                     px: { sm: 2, md: 4, lg: 0 },
                  }}
               >
                  <Typography
                     color={"primary"}
                     sx={{
                        fontFamily: "ExtraBold",
                        fontSize: "1.5rem",
                        textAlign: { xs: "center", md: "left" },
                        letterSpacing: 0.5,
                        lineHeight: 1.2,
                     }}
                  >
                     {sysmodules.title}
                  </Typography>
                  <AnimationOnScroll
                     animateIn="animate__slideInLeft"
                     animateOnce
                     animatePreScroll={false}
                     duration={0.75}
                  >
                     <Divider sx={{ borderBottomWidth: "4px", bgcolor: "#162c44", my: 1 }} />
                     {sysmodules.description}
                     {sysmodules.list}
                  </AnimationOnScroll>
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
};
export const descriptionZoneProx = (sysmodules, i) => {
   return (
      <Box
         sx={{
            flexDirection: "column",
            flex: 1,

            px: { xs: 1, sm: 6, md: 0 },
            py: { xs: 1, sm: 3, md: 0 },
            //alignItems: "center",
         }}
      >
         <Grid container direction={i % 2 === 0 ? "row" : "row-reverse"} spacing={{ lg: 1 }} sx={{}}>
            <Grid item xs={1.5}>
               <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animateOnce
                  animatePreScroll={false}
                  duration={0.75}
                  delay={750}
               >
                  <Box
                     width={"100%"}
                     height={"45px"}
                     mb={{ xs: "-50px", md: 0 }}
                     sx={{
                        maxHeight: "40px",
                        backgroundImage: sysmodules.icon,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                     }}
                  />
               </AnimationOnScroll>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", px: { md: 1 } }}>
               <Box sx={{ px: { sm: 2, md: 0 }, maxWidth: { md: "380px", lg: "430px" } }}>
                  <AnimationOnScroll
                     animateIn="animate__slideInLeft"
                     animateOnce
                     animatePreScroll={false}
                     duration={0.75}
                  >
                     <Typography
                        color={"primary"}
                        sx={{
                           fontFamily: "ExtraBold",
                           fontSize: { xs: "18px", sm: "1.5rem" },
                           textAlign: { xs: "center", lg: "left" },
                           letterSpacing: 0.5,
                           lineHeight: 1.2,
                           mt: { md: "-30px", lg: "-60px" },
                           px: { xs: 6, lg: 0 },

                           pt: { lg: 2 },
                        }}
                     >
                        {sysmodules.title}
                     </Typography>

                     <Divider
                        sx={{
                           borderBottomWidth: "4px",
                           bgcolor: "#162c44",
                           my: 1,

                           maxWidth: { xs: "220px", sm: "280px", md: "400px", lg: "100%" },
                           marginX: "auto",
                        }}
                     />
                     {sysmodules.description}
                     {sysmodules.list}
                  </AnimationOnScroll>
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
};
export const imageZone = (sysmodules, i) => {
   return (
      <Box
         sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",

            my: { sm: 0, md: 2, lg: 2 },
         }}
      >
         <Box
            sx={{
               backgroundImage: `url(${sysmodules.image})`,
               backgroundSize: "contain",
               backgroundRepeat: "no-repeat",
               backgroundPosition: "center center",
               height: { xs: "300px", sm: "350px", md: "280px", lg: "400px" },
               width: { xs: "100%", sm: "93%", md: "100%", lg: "95%" },
               //transform: { lg: i ? `translate(${positionX}%, 0)` : `translate(${positionX * -1}%, 0)` },
            }}
         />
      </Box>
   );
};
export const imageZoneProx = (sysmodules, i) => {
   return (
      <Box
         sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",

            my: { sm: 0, md: 2, lg: 2 },
         }}
      >
         <Box
            sx={{
               backgroundImage: `url(${sysmodules.image})`,
               backgroundSize: "contain",
               backgroundRepeat: "no-repeat",
               backgroundPosition: "center center",
               height: { xs: "300px", sm: "350px", md: "300px", lg: "400px" },
               width: { xs: "100%", sm: "93%", md: "35vw", lg: "95%" },
               //transform: { lg: i ? `translate(${positionX}%, 0)` : `translate(${positionX * -1}%, 0)` },
            }}
         />
      </Box>
   );
};

export const ServiceScreen = () => {
   const [selectedService, setSelectedService] = useState("");
   const scrollToElement = useCallback(() => {
      if (selectedService.length === 0) return;
      const element = document.getElementById(selectedService.substring(1, selectedService.length - 1));
      if (element) {
         element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
         localStorage.removeItem("selectedService");
      }
   }, [selectedService]);

   useEffect(() => {
      const item = JSON.stringify(localStorage.getItem("selectedService"));
      if (item) {
         setSelectedService(item);
         return scrollToElement();
      }
   }, [selectedService, scrollToElement]);

   return (
      <ThemeConfig>
         <ScrollToTop>
            <Box
               sx={{
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/proximo-servicio/Banner.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  width: "100%",
                  height: { xs: "450px", sm: "65vh", md: "615px", lg: "90vh" },
                  py: { lg: 10 },
               }}
            >
               <NavBar />
               <Grid container sx={{ display: "flex", justifyContent: "center", py: { xs: 10, md: 10 } }}>
                  <Grid item xs={12} md={10}>
                     <Slide direction={"right"} in={true} appear timeout={500}>
                        <Typography
                           color={"white"}
                           fontFamily={"bold"}
                           textAlign="center"
                           sx={{
                              lineHeight: 1.1,
                              fontSize: { xs: "50px", sm: "40px", md: "50px" },
                              textShadow: "0px 2px 2px  #081524",
                              alignItems: "center",
                              justifyContent: "center",
                              width: { lg: "80%" },
                              pl: { lg: "20%" },
                              pt: { xs: "0px", sm: "50px", md: "100px", lg: "0" },
                              px: { xs: "5%", sm: "5%" },
                           }}
                        >
                           PRÓXIMAS{" "}
                           <Typography
                              display={"inline"}
                              sx={{ lineHeight: 1.1, fontSize: { xs: "50px", sm: "40px", md: "50px" } }}
                              fontFamily={"ExtraBold"}
                           >
                              SOLUCIONES<br></br> Lecosy
                           </Typography>
                        </Typography>
                     </Slide>
                  </Grid>
                  <Grid
                     item
                     xs={12}
                     justifyContent={{ xs: "center", md: "flex-end" }}
                     display={"flex"}
                     px={{ md: 25 }}
                     pt={{ xs: 2.5, md: 13 }}
                  >
                     <Button
                        href="https://calendar.app.google/KcrgoEnbz9SJScGv8"
                        target={"_blank"}
                        sx={{ borderRadius: 3, p: 1, bgcolor: "#FFFFFF", "&:hover": { bgcolor: "#8A95A1" } }}
                     >
                        <Box sx={{ display: "flex", alignItems: "center", px: 1 }} color={"#162c44"}>
                           <Typography fontFamily={"bold"}>Contactar a un consultor</Typography>
                           <ArrowForwardIcon fontSize={"small"} sx={{ ml: 0.5 }} />
                        </Box>
                     </Button>
                  </Grid>
               </Grid>
            </Box>
            <Box
               sx={{
                  direction: "column",
                  spacing: 3,
                  p: { xs: 3, lg: 10 },
                  //py: { md: 8 },
                  mb: { xs: 4, md: 0 },
                  backgroundImage:
                     "url(https://images-lecosy.s3.us-west-2.amazonaws.com/assets/website/Fondo+beneficios.png)",
                  backgroundPosition: "center top",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  alignItems: "center",
                  justifyContent: "center",
                  alingSelf: "center",
               }}
            >
               <Grid container sx={{ py: { sm: 0, md: 6, lg: 0 } }}>
                  {FutureModules.map((sysModule, i) => {
                     return (
                        <Grid
                           item
                           md={12}
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              alingSelf: "center",
                           }}
                        >
                           <Box
                              columnGap={{ sm: 0, md: 6, lg: 0 }}
                              id={`${i + 1}`}
                              className={`${i + 1}`}
                              key={sysModule.title}
                              sx={{
                                 display: "flex",
                                 flexDirection: {
                                    xs: "column",
                                    md: i % 2 === 0 ? "row" : "row-reverse",
                                 },
                                 alignItems: { md: "center" },

                                 py: { xs: 4, sm: 2, md: 0 },
                                 px: { xs: 1, sm: 4 },
                                 maxWidth: { md: "1000px" },
                                 minWidth: { lg: "1240px" },
                                 textAlign: "justify",
                              }}
                           >
                              {descriptionZoneProx(sysModule, i)}
                              {imageZoneProx(sysModule)}
                           </Box>
                        </Grid>
                     );
                  })}
               </Grid>
            </Box>
            {ScrollToTopButton(window)}
            <Footer />
         </ScrollToTop>
      </ThemeConfig>
   );
};
